import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setTriggerVisible } from '../actions/navActions';
import Field from '../components/contact/Field';
import Map from '../components/contact/Map';
import Layout from '../components/Layout';
import { connect } from 'react-redux';
import SEO from '../components/Seo';

class Contact extends Component {
  state = {};

  componentDidMount() {
    if (!this.props.nav.triggerIsVisible) {
      this.props.setTriggerVisible(true);
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact" description="Tout l'équipe Marougrav est à votre disposition pour répondre à vos besoins. Une questions sur une gravure ou sur une impression ? N'hésitez pas à nous contacter." />
        <section className="Contact">
          <div className="wrapper container">
            <h2 className="mb-2">Contact</h2>
            <div className="row">
              <div className="col-md-6 mb-2" style={{ height: '100%' }}>
                <Map
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAqvu2_-QFXCI-kR1V6ONiVFlQv1FIsmgM"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `650px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
              <div className="col-md-6 Contact__form">
                <h3>Comment pouvons-nous vous aider ?</h3>
                <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                  <Field type="text" name="name" label="Entrez votre nom + prénom" placeholder="Entrez votre nom + prénom" />
                  <Field type="text" name="company" label="Entrez votre société" placeholder="Entrez votre nom + prénom" />
                  <Field type="email" name="email" label="Entrez votre email" />
                  <Field type="phone" name="phone" label="Entrez votre numéro de téléphone" />
                  <Field input={false} name="message" label="Entrez votre message" />
                  <input type="hidden" name="form-name" value="contact" />
                  <button type="submit" className="btn btn-white">Envoyer</button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

Contact.propTypes = {
  setTriggerVisible: PropTypes.func.isRequired,
  nav: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  nav: state.nav,
});

export default connect(mapStateToProps, { setTriggerVisible })(Contact);
