import React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

const Map = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: 50.6415893, lng: 4.676070500000037 }}
    options={{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      scrollwheel: false,
    }}
  >
    <Marker position={{ lat: 50.6415893, lng: 4.676070500000037 }} />
  </GoogleMap>,
));

export default Map;
