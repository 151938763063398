import React from 'react';
import PropTypes from 'prop-types';

const Field = props => {
  let field;
  if (props.input) {
    field = <input
      id={props.name}
      name={props.name}
      type={props.type}
      onFocus={e => handleOnFocus(e)}
      onBlur={e => handleOnBlur(e, props)}
    />;
  } else {
    field = <textarea
      id={props.name}
      name={props.name}
      rows={3}
      onFocus={e => handleOnFocus(e)}
      onBlur={e => handleOnBlur(e, props)}
    />;
  }
  return (
    <div className="Contact__input">
      <label htmlFor={props.name}>{props.label}</label>
      {field}
    </div>
  );
};

const handleOnFocus = e => {
  const parent = e.target.parentNode;
  if (!parent.classList.contains('Contact__input__focus')) {
    parent.classList.add('Contact__input__focus');
  }
};

const handleOnBlur = e => {
  const parent = e.target.parentNode;
  if (parent.classList.contains('Contact__input__focus') && e.target.value.length < 1) {
    parent.classList.remove('Contact__input__focus');
  }
};

Field.defaultProps = {
  input: true,
};

Field.propTypes = {
  input: PropTypes.bool.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Field;
